import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Result } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Project } from "../../models/Project";
import { authSelector } from "../../redux/slides/auth.slide";
import { projectSelector } from "../../redux/slides/project.slide";
import "./calculation.module.scss";
import { useTranslation } from "react-i18next";
import { UserRole } from "app/models/UserRole";

function CalculationDone(props: { project: Project }) {
  const { t } = useTranslation();
  const { data } = useSelector(projectSelector);
  const auth = useSelector(authSelector);
  return (
    <>
      <Result
        status="success"
        title={
          auth.user.role == UserRole.SELLER
            ? t("project.seller.checkout.done.success")
            : t("project.checkout.done.success")
        }
        subTitle={
          <>
            {data.linkedProject && !data.manual && (
              <div className="d-flex">
                <Alert
                  className="m-auto"
                  message={
                    <>
                      {t("project.checkout.done.msg")}{" "}
                      <a
                        className="fw-bold"
                        href={`/${auth.user.role}/projects/${data.linkedProject.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("clickHere")}
                      </a>
                      {t("project.checkout.done.msg2")}
                    </>
                  }
                  type="info"
                  showIcon
                />
              </div>
            )}
          </>
        }
        extra={[
          <Link key={"console"} to={`/${auth.user.role}/projects/${data.id}`}>
            <Button type="primary" icon={<ArrowLeftOutlined />}>
              {t("backToProject")}
            </Button>
          </Link>,
        ]}
      />
      <div className="d-flex flex-column align-items-center">
        {auth.user?.role === UserRole.BUYER && (
          <>
            <p>{t("project.checkout.done.msg3")}</p>
            <p>{t("project.checkout.done.msg4")}</p>
          </>
        )}
        {auth.user?.role === UserRole.BUYER && (
          <>
            <p>{t("project.seller.checkout.done")}</p>
          </>
        )}
      </div>
    </>
  );
}
export default CalculationDone;
